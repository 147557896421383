import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import { API_URL } from '../../../context/connection-string';
import {currencyConverter, formatDateAndTime, moneyFormat} from '../../../context/function';
import ReportSidebar from "../../../component/report-sidebar/report-sidebar";
import DataTable from "../../../component/data-table/data-table";

function CustomReport(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const header = ["S/N", "Staff Number", "Surname",  "OtherNames",  "Department", "Amount (₦)"];
    const [reportData, setReportData] = useState([])
    const [reportData2, setReportData2] = useState([])
    const [items, setItems] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [departmentList, setDepartmentList] = useState([]);
    const [payPointList, setPayPointList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        month_id: "",
        report_type: "",
        department: "",
        paypoint: "",
        division: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    // let total = 0;

    const getData = async () => {

        await axios.get(`${API_URL}report/payroll/custom-report-items`, login?.token).then((res) => {
            setItems(res.data)
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }
    const  showTable = () => {

        try {
            return reportData.filter(e=> parseFloat(e.amount) > 0).map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.staff_id}</td>
                        <td className="text-xs font-weight-bold">{item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.OtherNames}</td>
                        <td className="text-xs font-weight-bold">{item.Department}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.amount)}</td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    const  getTotal = () => {
        let total_amount = 0;
        reportData.filter(e=> parseFloat(e.amount) > 0).map((item, index) => {
            total_amount += item.amount;
        });
        return total_amount;
    }


    const getSalaryReport = async (salary_month, report_type) => {

        if (salary_month === "") {
        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}report/payroll/custom-report?salary_month=${salary_month}&report_type=${report_type}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        setReportData(res.data)
                        setReportData2(res.data)
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
        getUnitData();
    }, [])

    const getUnitData = async () => {

        await axios.get(`${API_URL}human-resources/employee/unit-data`, login?.token).then((res) => {
            setDepartmentList(res.data.departments);
            setPayPointList(res.data.departments);
            setDivisionList(res.data.division);
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
        if (val !== "" && formData.report_type !== ""){
            getSalaryReport(val, formData.report_type)
        }

    }

    const onEdit = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
    }

    const onChangeReportType = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
        if (val !== "" && formData.month_id !== ""){
            getSalaryReport(formData.month_id, val)
        }

    }

    const handleFilterByDivision = (e) => {
        console.log(e.target.value.toString().toLowerCase())
        let filtered = reportData2.filter((x) =>
            !x.division_id || x.division_id.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()))
        console.log(filtered)
        setReportData(filtered)
    }

    const handleFilterByDepartment = (e) => {
        const { value } = e.target;
        const filtered = reportData2.filter((x) => {
            const departmentId = x.department_id ? x.department_id.toString().toLowerCase() : '';
            const filterValue = value.toString().toLowerCase();
            return !departmentId || departmentId.includes(filterValue);
        });
        setReportData(filtered);
    }

    const handleFilterByPayPoint = (e) => {
        let filtered = reportData2.filter((x) =>
            !x.pay_point || x.pay_point.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()))
        setReportData(filtered)
    }


    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Custom Report"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/report/dashboard' },
                                    { name: 'Custom Report', link: '/report/custom-report' }]}
                />

                <div className="row">
                    <ReportSidebar/>
                    <div className="col-xl-10">
                        <div className=' mb-5'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <input type="month" id="month_id"
                                                   className="form-control"
                                                   value={formData.month_id}
                                                   onChange={onChange}
                                                   max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                   required />
                                        </div>
                                    </div>
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="month_id">Select Report Type</label>
                                            <select className="form-control" id="report_type" value={formData.report_type} onChange={onChangeReportType}>
                                                <option value="">Select Report Type</option>
                                                {
                                                    items.length > 0 && items.map((item, int)=> {
                                                        return (
                                                            <option key={int} value={item.item_name}>{item.item_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div className="">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <h3 className="card-title">{formData.report_type} </h3>
                                                <div className="row mt-3  mb-3 p-4" style={{backgroundColor: '#EEEEEE'}}>
                                                    <h3>Filter</h3>
                                                    <div className="col-md-4 pb-3 mt-3 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="department">Division </label>
                                                            <select className='form-control' id="division" onChange={onEdit} value={formData.division} required>
                                                                <option value={""}> -select division- </option>
                                                                {
                                                                    divisionList?.map((x, i) => {
                                                                        return (
                                                                            <option key={i} value={x.division_id}> {x.division_name} </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 pb-3 mt-3 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="department">Department </label>
                                                            <select className='form-control' id="department" onChange={onEdit} value={formData.department} required>
                                                                <option value={""}> -select department- </option>
                                                                {
                                                                    departmentList?.map((x, i) => {
                                                                        return (
                                                                            <option key={i} value={x.department_id}> {x.department_name} </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 pb-3 mt-3 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="department">Pay point </label>
                                                            <select className='form-control' id="paypoint" onChange={onEdit} value={formData.paypoint} required>
                                                                <option value={""}> -select pay point- </option>
                                                                {
                                                                    payPointList?.map((x, i) => {
                                                                        return (
                                                                            <option key={i} value={x.department_id}> {x.department_name} </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DataTable header={header} date={"("+formatDateAndTime(formData.month_id, 'month_and_year')+")"} caption={`Total Amount: ${currencyConverter(getTotal() ?? 0)}`} body={showTable()} title={formData.report_type+" Report"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(CustomReport);
